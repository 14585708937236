/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import styled, { createGlobalStyle, ThemeProvider } from "styled-components"
import BackgroundImage from "../images/background-paper.png"
import { device } from "../util/media"
// import "animate.css/animate.css"
import { ArtDecoWrapper } from "./ArtDecoWrapper"

const Spinnaker = `font-family: 'Spinnaker', sans-serif;`
const Poiret = `font-family: 'Poiret One', cursive;`
const Enriqueta = `font-family: 'Enriqueta', serif;`

const theme = {
  fontDefault: Spinnaker,
  fontTitle: Poiret,
  fontButton: Enriqueta,
  primary: "#9d8a02",
  secondary: "#3d4978",
  background: "#FDF6EB",
  greyGreen: "#1a3628",
  turkies: "#63c39f",
}

const GlobalStyle = createGlobalStyle`
  
  @import url('https://fonts.googleapis.com/css?family=Poiret+One|Spinnaker|Enriqueta&display=swap');
  
  body {
    margin:0;
    padding: 10px;
    ${Enriqueta};
    color: ${theme.secondary};
    position: relative;
    min-height: 100vh;
  }
  
  body::after {
    content: "";
    background: url(${BackgroundImage}) repeat;
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;   
  }

  .ReactModal__Body--open {
    overflow:hidden;
  }

  a {
    color: ${theme.turkies}
  }

  ol, li {
    padding-left: 20px;
  }

  .gallery {
    display: grid;
    @media ${device.tablet} {
      grid-template-columns: repeat(3, 200px);
    }
    grid-gap: 10px;
    .grid-item {
      width: 200px;
      height: 200px;

      border: 1px solid ${theme.turkies};
      img {
        width: 200px;
        height: 200px;
        object-fit: cover;
      }
    }
  }
`
const Wrapper = styled.div`
  width: 100%;
  min-width: 200px;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  position: relative;
`

const Credit = styled(({ className }) => {
  return (
    <div className={className}>
      Made with ❤️️ by <br />
      <a href="https://www.instagram.com/theluk246/">@theluk246</a> and{" "}
      <a href="https://www.instagram.com/sae_raah_eytsch/">@sae_raah_eytsch</a>{" "}
      <br />© 2020
    </div>
  )
})`
  color: ${props => props.theme.primary};
  ${props => props.theme.fontButton};
  font-size: 0.8em;
  text-align: center;
  max-width: 400px;
  border-top: 1px solid #ddd;
  padding-top: 20px;
  margin: 50px auto 0;
  line-height: 1.3em;
  a {
    color: ${props => props.theme.primary};
  }
`

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyle />
        <Wrapper>
          <ArtDecoWrapper>
            {children}
            {` `}
            <Credit />
          </ArtDecoWrapper>
        </Wrapper>
      </React.Fragment>
    </ThemeProvider>
  )
}

export const withLayout = Component => props => {
  return (
    <Layout>
      <Component {...props} />
    </Layout>
  )
}

export default Layout
