// Import the functions you need from the SDKs you need
import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBTMIFkFFJ8SNB3-03eEtXcNbTjmKnOtmg",
  authDomain: "wedding-8406e.firebaseapp.com",
  databaseURL: "https://wedding-8406e.firebaseio.com",
  projectId: "wedding-8406e",
  storageBucket: "wedding-8406e.appspot.com",
  messagingSenderId: "783291572863",
  appId: "1:783291572863:web:a52b101c5721209490154a",
  measurementId: "G-P3FRZMLDYB",
}

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig)

const db = app.firestore()
const storage = app.storage()

if (process.env.GATSBY_EMULATORS) {
  // db.useEmulator("localhost", 8080)
  // storage.useEmulator("localhost", 9199)
}

export { app, db, storage }
