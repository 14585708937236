import React from "react"
import Corner from "../../assets/ornament-corner.svg"
import styled from "styled-components"
export const ArtDecoWrapper = ({ children, color, ...rest }) => {
  return (
    <>
      <TopLeftCorner color={color} />
      <BottomLeftCorner color={color} />
      <TopRightCorner color={color} />
      <BottomRightCorner color={color} />

      <FullBorder {...rest} color={color}>
        <InnerBorderVertical color={color}>
          <InnerBorderHorizontal color={color}>
            {children}
          </InnerBorderHorizontal>
        </InnerBorderVertical>
      </FullBorder>
    </>
  )
}

export const ArtDecoNested = styled(({ className, children, ...rest }) => {
  return (
    <div {...rest} className={className}>
      <div>{children}</div>
    </div>
  )
})`
  margin: 0 -25px;
  ${props => (props.pullTop ? "margin-top: -45px; margin-bottom: 50px;" : "")}
  padding: 10px;
  position: relative;
  background-color: ${props =>
    props.bg ? props.theme[props.bg] : "transparent"};
  > div {
    display: flex;
    position: relative;
  }
`

const TopLeftCorner = styled(Corner)`
  position: absolute;
  ${props => (props.color ? `color: ${props.color}` : ``)};
  top: 0;
  left: 0;
`
const BottomLeftCorner = styled(Corner)`
  transform: rotate(-90deg);
  position: absolute;
  ${props => (props.color ? `color: ${props.color}` : ``)};
  bottom: 0;
  left: 0;
`
const TopRightCorner = styled(Corner)`
  transform: rotate(90deg);
  position: absolute;
  ${props => (props.color ? `color: ${props.color}` : ``)};
  top: 0;
  right: 0;
`
const BottomRightCorner = styled(Corner)`
  transform: rotate(-180deg);
  position: absolute;
  ${props => (props.color ? `color: ${props.color}` : ``)};
  bottom: 0;
  right: 0;
`
const FullBorder = styled.div`
  border: 1px solid ${props => props.color || props.theme.turkies};
  margin: 10px;
  padding: 20px;
  flex: 1;
  display: flex;
  padding: 3px 23px;
`
const InnerBorderVertical = styled.div`
  border: 1px solid ${props => props.color || props.theme.turkies};
  border-left-width: 0px;
  border-right-width: 0px;
  flex: 1;
  display: flex;
  padding: 19px 0;
`
const InnerBorderHorizontal = styled.div`
  border: 1px solid ${props => props.color || props.theme.turkies};
  border-top-width: 0px;
  border-bottom-width: 0px;
  margin: 0 -20px;
  flex: 1;
  padding-bottom: 20px;
`
